import { ICategory, ICategoryBase, ICategoryParent } from '../models/category.model';

export class CategoryHelper {
  public static getCategoryUrl(category?: ICategory | ICategoryBase | ICategoryParent, navigateUrl = '/categories/', ociMode = false, supplierId: number): string {
    if (!supplierId) {
      return `${ociMode ? '/oci' : ''}${category?.slug ?? navigateUrl}`;
    } else {
      return `${category?.slug ?? navigateUrl}supplier/${supplierId}`;
    }
  }
}
